import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  }
}

export default props => {
  // const data = useStaticQuery(aboutQuery)
  // const image = getImage(data.avatar)

  return (
    <Layout {...props}>
      <Seo title='About' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='About Me'
          />
          <Divider />
          {/* <Box sx={styles.imageWrapper}>
            <Img image={image} />
            <Button sx={styles.button}>Contact Me</Button>
          </Box>
          <Divider /> */}
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section title='My Story'>
                <Text variant='p'>
                  Hi 👋! My name is Georgios Karanikas, I have been working as FullStack Software Engineer for about 10 years now over
                  different sectors, including food, construction, finance and other industries.
                </Text>
                <Text variant='p'>
                  Over the course of those years I have worked with different technologies and stacks both in backend and in frontend 
                  but I really enjoy working with Java and React! I like getting my hands dirty with different things this is why you will
                  see me getting involved in the whole process of software development from coding to ops. I really like automating stuff, 
                  working with clean code and best practices all over the stack.
                </Text>
                <Text variant='p'>
                  I have experience working in large and small teams as well as freelancing for single person projects. 
                </Text>
                <Text variant='p'>
                  Happy husband and father of two, currently living in Lamia, Greece. I love winter, mountains and basketball!
                </Text>
              </Section>
            </Box>
            <Box sx={styles.column}>
              <Section title='Backend Background'>
                <Card variant='paper'>
                  I have worked with Java's different frameworks including Spring,
                  Micronaut and Quarkus to build various sized services communicating 
                  either synchronously or asynchronously. Additionally I have worked 
                  on several projects developed on Node.js.
                </Card>
              </Section>
              <Divider />
              <Section title='Frontend Background'>
                <Card variant='paper'>
                  My frontend journey started with JS and jQuery and over the years 
                  I have worked with different frameworks/libs including Ember.js,
                  AngularJS, Angular and my personal favorite React ❤️.
                </Card>
              </Section>
              <Divider />
              <Section title='Ops Background'>
                <Card variant='paper'>
                  I really like automation and I have had my share or working with CIs 
                  on GitLab CI, Jenkins, CircleCI, and Travis to introduce CI/CD to numerous 
                  applications deployed in different stacks varying from static assets to K8s.
                  I have worked with Ansible as well and AWS Cloudformation.
                </Card>
              </Section>
            </Box>
          </Flex>
        </Main>
      </Stack>
    </Layout>
  )
}

// const aboutQuery = graphql`
//   query AboutQueryShadowed {
//     avatar: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
//       childImageSharp {
//         gatsbyImageData(
//           width: 1140
//           height: 500
//           transformOptions: { cropFocus: NORTH }
//         )
//       }
//     }
//   }
// `
